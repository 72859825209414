@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");
@import "../global-styling/colors.css";
@import "../global-styling/container.css";
@import "../global-styling/datepicker.css";
@import "../global-styling/skeleton.css";

@font-face {
  font-family: Maax;
  src: url("/fonts/Maax-205TF/Maax-Regular-205TF/Maax-Regular-205TF.woff");
  font-weight: 400;
}

@font-face {
  font-family: Maax;
  src: url("/fonts/Maax-205TF/Maax-Medium-205TF/Maax-Medium-205TF.woff");
  font-weight: 500;
}

@font-face {
  font-family: Maax;
  src: url("/fonts/Maax-205TF/Maax-Light-205TF/Maax-Light-205TF.woff");
  font-weight: 300;
}

@font-face {
  font-family: Maax;
  src: url("/fonts/Maax-205TF/Maax-Bold-205TF/Maax-Bold-205TF.woff");
  font-weight: 600;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  background: var(--background-color);
  -webkit-overflow-scroll: touch;
}

body * {
  font-family: Maax, "Noto Sans", -apple-system, "Ubuntu", sans-serif;
  font-weight: 400;
}

body::-webkit-scrollbar {
  width: 0;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-gray);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-gray);
}

#screenLoader {
  position: absolute;
  opacity: 0;
  height: 0px;
  width: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nprogress-busy #screenLoader {
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 1300ms ease;
  transition-delay: 1000ms;
  height: 100vh;
  z-index: 999999;
  /*transform: translateZ(999999px);*/
  -webkit-transform: translate3d(0, 0, 0);
}

.screen-loader-indicator {
  opacity: 0;
  margin-left: calc(50vw);
  transform: translateX(-50%);
  transition: opacity 1300ms ease;
  transition-delay: 1000ms;
}

.nprogress-busy .screen-loader-indicator {
  opacity: 0.9;
}
