:root {
    --red: #e84a50;
    --red-transparent: #faeaeb;

    --green: #00bb8b;
    --light-green: #b2eadc;
    --transparent-green: #e5f8f3;

    --black: rgb(0, 20, 27);
    --darkest-gray: #bbc1c2;
    --dark-gray: #7f8c8d;
    --gray: #dbdede;
    --light-gray: #e5e8e8;
    --transparent-gray: #f2f3f3;
    --border-color: #f8f9f9;
    --almost-white: #f9f9f9;
    --placeholder-gray: #788387;
    --langpicker-border-gray: #f2f3f5;
    --cancel-button-gray: #8a9798;
    --skeleton-gray: #eceeee;
    --action-timestamp-gray: #bfc5c6;

    --final-border-color: #EDEEEE;

    --background-color: #f7f8f8;

    --yellow: #f9e319;
    --yellow-dark: #f5ce00;
    --yellow-light: #fefad6;

    --darkest-blue: #008fa1;
    --dark-blue: #00adbb;
    --blue: #b2e6ea;
    --light-blue: #e5f6f8;
    --transparent-blue: #f0fafb;
}
